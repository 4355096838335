<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 



import axios from 'axios';
import Swal from 'sweetalert2';
import modalInvoices from "../modal/modals-invoice-list";



var _ = require('lodash');

/**
 * Form wizard component
 */


export default {
  page: {
    title: "Form Wizard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },





  components: {
    FormWizard,
    TabContent,
    PageHeader,
    Layout,
   
    modalInvoices,

  },
  data() {

    return {
      title: "Form Wizard",

      items: [
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Form Wizard",
          active: true,
        },
      ],
      // props: {
      //   orderId: {
      //     type: String
      //   }
      // },
      orderIdprops: '',

      dataProps: {

      },
      searchRegex: '',
      selectedOption: '',
      propsModalObj: {
        orderid: 'jkadsjkasdjk',
        passingtest: 'ok',
      },
      productList: [],
      invoiceList: [],
      arraytab2: [
        {
          name: '',
          clientGroup: '',
          address: '',
          phoneNumber: '',
          phonenumberOptional: ''
        }

      ],
      loopJumlah: false,
      dialog: false,

      formData: {
        orderId: '',
        phoneNumber: ''
      },
      input: {
        orderId: '',
        phoneNumber: ''
      },
      users: {

      },
      enabled5: false,
      errorMsg: null,
      errorMsg1: null,
      errorMsg3: null,
      stage: '',
      loadingWizard: false,
      checkboxcheck: null,
      choice: {
        berdasarkan: {
          title: 'Pilih',
          value: ''
        }
      },

      listOption: [
        'orderId',
        'phoneNumber'
      ],
      options: [],
      berdasarkan: null,
      showbutton1: "",
      submitform: false,
      datafound: false,
      idProduct: null,
      isHovered: false,
      isLoading: false,
      suggestRegex: false,
      inputRegextype: false,
      inputRegexCheck: false,
      autoChecked: false,
      convertIdr:'',


    };
  },

  


  watch: {
    'berdasarkan': {
      immediate: true,
      handler(newValue) {
        if (newValue === 0 || newValue) {
          console.log(newValue);
          if (newValue == 'orderId') {
            this.showbutton1 = newValue
            this.errorMsg1 = null
          } else if (newValue == 'phoneNumber') {
            this.showbutton1 = newValue
            this.errorMsg = null
          }
        }
      },

    },

    'idProduct': {
      immediate: true,
      handler(listener) {
        console.log("cek",listener);
        if (listener === 0 || listener) {

          this.checkboxcheck = listener;

        }
      },

    },

    'searchRegex': {
      immediate: true,
    async handler(listenerRegex) {
        console.log(listenerRegex);
         this.errorMsg3 = false
       
        if (listenerRegex.length >= 4) {
          
          let testing = listenerRegex || ''

         
          if(_.startsWith(testing,'08') || _.startsWith(testing,'628'))
          {
            this.showbutton1 = 'phoneNumber'
            await this.regexSearchPhoneNumber(listenerRegex)
          }
         else {
          this.showbutton1 = 'orderId'
          console.log("orderbos");
          await this.regexSearchOrderId(listenerRegex)
         }

          
          

        } else if (listenerRegex.length < 4) {
          this.handlerSuggest(false)


        }

      },

    },
    'selectedOption': {
      immediate: true,
      handler(listenerRegexchose) {

        let testing = listenerRegexchose || ''

        let inputTest = testing.includes('1160')
        if (inputTest) {

          this.showbutton1 = 'orderId'
        }
        else if (!inputTest) {

          this.showbutton1 = 'phoneNumber'

        }


      },
    }




  },
  methods: {
    handleValidation(isValid, tabIndex) {
      console.log('Tab: ' + tabIndex + ' valid: ' + isValid)

    },
    handlerSuggest(val) {
      this.suggestRegex = val

    },
    isChose(e) {
      console.log("e", e.target.value);
      this.searchRegex = e.target.value
      this.handlerSuggest(false)

    },

    async regexSearchOrderId(inputan) {
      let orderId = inputan
     

      await axios.get(process.env.VUE_APP_URL_FEATHERS+'dynamic-search-product?orderId='+orderId)
        .then(response => {


          this.options = []
          this.options = response.data
          console.log(this.options)
          console.log(this.options.length);
          if (response.data.length == 1) {
            this.suggestRegex = true
            this.options = response.data
            this.inputRegexCheck = true
            return this.options
          }
          else {
            this.inputRegexCheck = false
            this.suggestRegex = false
          }
         
          return this.options


        })
        .catch(error => {
          console.error("There was an error!", error);
        });

    },
    async regexSearchPhoneNumber(inputan) {
      
      let phonenumber = inputan
     
      await axios.get(process.env.VUE_APP_URL_FEATHERS+'dynamic-search-product?phonenumber='+phonenumber)
        .then(response => {


          this.options = []
          
          console.log(this.options)
          console.log(this.options.length);
          if (response.data.length == 1) {
            this.suggestRegex = true
            this.options = response.data
            this.inputRegexCheck = true
            return this.options
          }
          else {
            this.inputRegexCheck = false
            this.suggestRegex = false
          }
         


        })
        .catch(error => {
          console.error("There was an error!", error);
        });

    },


    // filteredOptions() {
    //   if (this.options.length != 0) {
    //     console.log("filteredOptions");
    //     return this.options.filter(option => {
    //       return option
    //     });
    //   }
    // },

    // filterOptions() {
    //   console.log("filterOptions");
    //   this.filteredOptions = this.options.filter(option => {
    //     return option
    //   });
    // },

    async validadeFormOne() {
      this.suggestRegex = false
      console.log(this.showbutton1);
      if (this.showbutton1 == 'orderId') {
        if (this.$refs.inputRegex._value != "") {
          this.setLoading(true)

          await this.search(this.$refs.inputRegex._value)

          if (this.inputRegexCheck == false) {
            
            this.errorMsg3 = true
            return false
          }
          this.isLoading = true
          this.autoChecked= true
          return true
        }
        else if (this.$refs.inputRegex == "") {
          this.errorMsg = true
          this.errorMsg1 = null
          return false
        }
      }

      if (this.showbutton1 == 'phoneNumber') {
        if (this.$refs.inputRegex._value != "") {
          console.log("askdjaksldj");
          this.setLoading(true)
        
          await this.search(this.$refs.inputRegex._value)
          if (this.inputRegexCheck == false) {
           
           
            return false
          }
          this.isLoading = true;

          return true
        }
        else if (this.$refs.inputRegex._value == "") {
          this.errorMsg3 = true
          return false
        }
        else if (this.inputRegexCheck == false) {
          console.log("disini");
          this.errorMsg3 = true
          return false

        }
      }

    },
    async validadeFormTwo() {

      console.log(this.checkboxcheck);
      if (this.showbutton1 == 'orderId'){
       
        
       this.checkboxcheck = this.arraytab2[0].orderId;
       
      }
      if (this.checkboxcheck == null) {
        console.log("alerts");
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'Silahkan pilih Product terlebih dahulu',
          showConfirmButton: true,

        })
        return false
      } else {
       await this.setLoading(true)
       console.log(this.checkboxcheck                                                                       );
        await this.invoiceListSearch(this.checkboxcheck);
        this.checkboxcheck=null
        return true
      }

    },

    reset() {
      this.$refs.form.reset();
    },
    setLoading: function (value) {
      this.loadingWizard = value
      console.log(value);

    },

    setLoadingComponenTab2: function (value) {
      if (value == false) {
        console.log("setLoadingComponenTab2 true");
        return true
      }
      else {
        console.log("setLoadingComponenTab2 false");
        return false
      }


    },

    async search(cekData) {
      console.log("cek", cekData)
      console.log("type Seach", this.showbutton1);

      
      if (this.showbutton1 === 'orderId') {

        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-product-search?orderId='+cekData)
          .then(response => {
            console.log(response)

            this.productList = response.data;
           

            

            console.log(this.inputRegexCheck);
            if (response.data.length == 1) {
              this.arraytab2 = []
            this.arraytab2 = response.data;
              this.inputRegexCheck = true
            }

          })
          .catch(error => {
            console.error("There was an error!", error);
          });
        await this.setLoading(false)
      } else if (this.showbutton1 === 'phoneNumber') {

        await axios.get(process.env.VUE_APP_URL_FEATHERS+ 'costumer-invoices?phonenumber='+cekData)
          .then(response => {


            // if (response.data.length >= 2) {
            //   this.productList = response.data;
            //   this.loopJumlah = true;
            // }
           
            this.productList = response.data;
            
            

            console.log(this.inputRegexCheck);
            if (response.data.length >= 1) {
              this.inputRegexCheck = true
              this.arraytab2 = response.data;
              
            }
            else {
              this.errorMsg3 = true
              
            }

            console.log(this.productList);

            // const test = response.data[0].customerId;
            // this.$router.push('/Product-view/'+test)


          })
          .catch(error => {
            console.error("There was an error!", error);
          });
        await this.setLoading(false)




      } else {
        console.log('tidak di temukan')
      }


    },
    async invoiceListSearch(orderId) {
      
      console.log("invo", orderId);
      
      await axios.get(process.env.VUE_APP_URL_FEATHERS+'costumer-invoices?orderId='+orderId)
        .then(response => {
          console.log(response)

          this.invoiceList = response.data;
          console.log(this.invoiceList);
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
      await this.setLoading(false)


    },
    handleHover(hovered) {
      this.isHovered = hovered
    }


  },







  middleware: "authentication",

};



</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Invoice Search</h4>
            <form-wizard color="#5b73e8" backButtonText="back" stepSize="lg" @on-loading="setLoading"
              @on-validate="handleValidation" ref="wizard">
              <tab-content icon="mdi mdi-account-circle" :before-change="validadeFormOne" ref="tabOne">
                <div class="row">
                  <div class="col-8">


                    <div class="form-group row mb-3">

                      <label class="col-md-3 col-form-label">Silahakan Masukan Order ID / No hanphone</label>
                      <div class="col-md-4">
                        <div class="mb-3 row">
                          <div class="col-md-15">
                            <input style="width:250px" id="dynamicSearch" type="text" v-model="searchRegex"
                              ref="inputRegex" placeholder="Search" class = "form-control bg-light border-light rounded"><br>
                            <div v-show="errorMsg3">
                              <span class="error">Data tidak di temukan silahkan Periksa inputan anda</span>
                            </div>
                            <div v-show="false">
                              <select name="testing" id="test" v-model="selectedOption" style="width:250px"
                                :size="options.length + 1" @click="isChose" class = "form-control bg-light border-light rounded">

                                <option id="option._id" v-for="option in options" v-bind:value="option.inputan"
                                  :key="option._ID" class = "bg-light border-light rounded">{{ option.inputan }}
                                </option>

                              </select>
                            </div>



                          </div>
                        </div>

                      </div>

                    </div>



                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>

              <tab-content icon="mdi mdi-face-profile" :before-change="validadeFormTwo" ref="tabTwo">
                <div class="loader" v-if="loadingWizard">
                </div>

                <div class="col">
                  <div v-if="!loadingWizard">

                    <b-form-group inline class="md-center" id="example text" label-cols-md="2" label-cols-lg="2"
                      label="Nama pelanggan:" label-for="text">

                      <b-form-row>
                        <b-form-input for="text" :value="arraytab2[0].name" :disabled=true
                          style="width:30%"></b-form-input>
                        <b-col cols="2">
                          <label class="ps-md-4 pe-md-1" label-for="checkboxWatcher">Nomor handphone:</label>
                        </b-col>

                        <b-col>
                          <b-form-input for="checkboxWatcher" :value="arraytab2[0].phonenumber" :disabled=true style="width:30%" />
                        </b-col>

                      </b-form-row>

                    </b-form-group>
                    <p></p>

                    <p>
                      <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Kode Area:"
                        label-for="text">
                        <b-form-row>
                          <b-form-input for="text" :value="arraytab2[0].clientGroup" :disabled=true
                            style="width:30%"></b-form-input>
                          <b-col cols="2">
                            <label class="ps-md-4 pe-md-1" label-for="phonenumberOptional">Nomor handphone Alternatif
                              :</label>
                          </b-col>

                          <b-col>
                            <b-form-input for="text" :value="arraytab2[0].phonenumberOptional" :disabled=true
                              style="width:30%"></b-form-input>
                          </b-col>

                        </b-form-row>

                      </b-form-group>

                    </p>
                    <p>
                      <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Alamat"
                        label-for="text">
                        <b-form-input for="text" :value="arraytab2[0].address" :disabled=true
                          style="width:30%"></b-form-input>
                      </b-form-group>

                    </p>
                  </div>


                  <b-card-group deck>
                    <div class="row">
                      <div class="col-md-4 col-sm-6 collapsed text-dark" data-toggle="collapse"
                        v-b-toggle="`${items.id}-collapse`" v-for="items in productList" :key="items.id">
                        <b-card no-body>

                          <b-card-body header-class="bg-transparent border-primary" class="border border-primary  ">
                            <b-form-radio v-model="idProduct" :value="items.orderId" class="reverse text-end"
                              v-if="items.status === 'active'" name="radio-button" >

                            </b-form-radio>
                            <b-card-title>
                              <h2 class="card-title">
                                <span class="badge bg-success font-size-17 me-1" v-if="items.status === 'active'"><i
                                    class="mdi mdi-star"></i> Active</span>
                                <span class="badge bg-warning font-size-17 me-1" v-if="items.status === 'pending'"><i
                                    class="mdi mdi-exclamation-thick"></i> Pending</span>
                                <span class="badge bg-danger font-size-17 me-1" v-if="items.status === 'suspend'"><i
                                    class="mdi mdi-stop-circle-outline"></i> Suspend</span>
                                <!-- <mark style="background: #06ab32!important">{{ items.status
                              }}</mark> -->
                              </h2>
                            </b-card-title>
                            <b-card-text style="height: 3rem">
                              <h3 class="mb-3" name="productName">
                                {{ items.productName }}
                              </h3>
                            </b-card-text>

                            <div class="custom-accordion">

                              <div class="card">
                                <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                                  v-b-toggle="`${items.id}`">
                                  <div class="p-4">
                                    <div class="media align-items-center">
                                      <div class="me-3">
                                        <i class="uil uil-document-layout-right text-primary h2"></i>
                                      </div>
                                      <div class="media-body overflow-hidden">
                                        <h5 class="font-size-16 mb-1">Product Info</h5>
                                        <p class="text-muted text-truncate mb-0">

                                        </p>
                                      </div>
                                      <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                    </div>
                                  </div>
                                </a>


                                <b-collapse :id="`${items.id}`">
                                  <div class="p-2 border-top">
                                    <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3"
                                      label="Order ID" label-for="text">
                                      <b-form-input for="text" :value="items.orderId" :disabled=true
                                        style="width:50%">:</b-form-input>
                                    </b-form-group>


                                    <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3"
                                      label="Harga Paket" label-for="text">
                                      <b-form-input for="text" :value="items.amount" :disabled=true
                                        style="width:50%">:</b-form-input>
                                    </b-form-group>

                                    <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3"
                                      label="Harga Paket" label-for="text" v-show= "items.status == 'pending'">
                                      <b-form-textarea  for="text" :value="items.massage" :disabled=true
                                        style="width:90%">:</b-form-textarea>
                                    </b-form-group>


                                  </div>
                                </b-collapse>
                              </div>


                            </div>
                          </b-card-body>

                        </b-card>
                      </div>
                    </div>

                  </b-card-group>


                </div>


                <!-- end row -->
              </tab-content>

              <tab-content icon="uil uil-receipt">
                <div class="loader" v-if="loadingWizard">
                </div>
                <div class="p-4">
                  <div class="media align-items-left">
                    <div clas="me-3">
                      <i class="uil uil-receipt text-primary h2">Invoice List</i>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1"></h5>
                      <p class="text-muted text-truncate mb-0">

                      </p>
                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-12">

                    <div class="card" v-for="items in invoiceList" :key="items.id">
                      <h2 class="card-title">
                        <span class="badge bg-soft-primary font-size-17 me-1" v-if="items.status === 'settlement'"><i
                            class="uil uil-calender"></i> {{ items.bulan }}</span>
                        <span class="badge bg-success font-size-17 me-1" v-if="items.status === 'settlement'"><i
                            class="mdi mdi-star"></i>Settelment</span>

                        <span class="badge bg-soft-primary font-size-17" v-if="items.status === 'pending'"><i
                            class="uil uil-calender"></i> {{ items.bulan }} </span>
                        <span class="badge bg-warning font-size-17 me-1 ms-1" v-if="items.status === 'pending'"><i
                            class="mdi mdi-exclamation-thick"></i>Pending </span>

                        <span class="badge bg-soft-primary font-size-17 me-1" v-if="items.status === 'cancel'"><i
                            class="uil uil-calender"></i> {{ items.bulan }} </span>
                        <span class="badge bg-danger font-size-17 me-1" v-if="items.status === 'cancel'"><i
                            class="mdi mdi-stop-circle-outline"></i> Cancel </span>
                        <!-- <mark style="background: #06ab32!important">{{ items.status
                              }}</mark> -->
                      </h2>
                      <div class="custom-accordion">
                        <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                          v-b-toggle="`${items.id}`">
                          <div class="p-3">
                            <div class="media align-items-center">
                              <div class="me-3">
                                <i class="uil uil-bill text-primary h2"> </i>
                              </div>
                              <div class="media-body overflow-hidden">
                                <h5 class="font-size-14 mb-1"> {{ items.orderId }} </h5>
                                <p class="text-muted text-truncate mb-0 font-size-10"
                                  v-if="items.status === 'settlement'">
                                  Di Bayar pada : {{ items.paidAt }}
                                </p>
                              </div>
                              <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                            </div>
                          </div>
                        </a>

                        <b-collapse :id="`${items.id}`">
                          <div class="p-4 border-top">
                            <div>
                              <h5 class="font-size-14 mb-3">Detail Invoice :</h5>
                              <div class="row-md">
                                <div class="col-md-12">


                                  <b-form-group inline class="md-center" id="example text" label-cols-md="2"
                                    label-cols-lg="2" label="No Invoice:" label-for="text">
                                    <b-form-row>
                                      <b-form-input for="InvoiceId" :value="items.orderId" :disabled=true
                                        style="width:35%" id="InvoiceId"></b-form-input>
                                      <b-col cols="3">
                                        <label class="ps-md-4 pe-md-3 ps-lg-4 pe-lg-3 " label-for="grossAmount">Harga
                                          Product+ppn10%:</label>
                                      </b-col>

                                      <b-col>
                                        <b-form-input for="grossAmount" :value="items.grossAmount" :disabled=true
                                          style="width:60%" />
                                      </b-col>

                                    </b-form-row>



                                  </b-form-group>
                                  <p></p>

                                  <b-form-group inline class="md-center" id="example text" label-cols-md="2"
                                    label-cols-lg="2" label="Nama Product:" label-for="text">
                                    <b-form-row>
                                      <b-form-input for="text" :value="items.productName" :disabled=true
                                        style="width:35%"></b-form-input>
                                      <b-col cols="3">
                                        <label class="ps-md-4 pe-md-3 ps-lg-4 pe-lg-3" label-for="diBayarPada"
                                          v-show="items.status === 'settlement'">Di Bayar Pada :</label>
                                      </b-col>

                                      <b-col>
                                        <b-form-input for="grossAmount" :value="items.paidAt" :disabled=true
                                          style="width:60%" v-show="items.status === 'settlement'" />
                                      </b-col>

                                    </b-form-row>
                                  </b-form-group>
                                  <p></p>

                                  <b-form-group inline class="md-center" id="example text" label-cols-md="2"
                                    label-cols-lg="2" label="Harga Product:" label-for="text">
                                    <b-form-row>
                                      <b-form-input for="text" :value="items.amount" :disabled=true
                                        style="width:35%"></b-form-input>
                                      <b-col cols="3">
                                        <label class="ps-md-4 pe-md-3 ps-lg-4 pe-lg-3" label-for="metodePembayaran :"
                                          v-show="items.status === 'settlement'">Metode Pembayaran :</label>
                                      </b-col>

                                      <b-col>
                                        <b-form-input for="grossAmount" :value="items.method" :disabled=true
                                          style="width:60%" v-show="items.status === 'settlement'" />


                                      </b-col>


                                      <modalInvoices v-show="items.status === 'pending'"
                                        :orderIdprops='`${items.orderId}`'>

                                      </modalInvoices>

                                    </b-form-row>

                                  </b-form-group>
                                  <p></p>

                                </div>
                              </div>

                            </div>

                          </div>

                        </b-collapse>

                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">Thank you !</h3>

                      <p class="w-75 mb-2 mx-auto">
                        Quisque nec turpis at urna dictum luctus. Suspendisse
                        convallis dignissim eros at volutpat. In egestas mattis
                        dui. Aliquam mattis dictum aliquet.
                      </p>

                      <div class="mb-3">
                        <div class="custom-control custom-checkbox">
                          <input id="customCheck1" type="checkbox" class="custom-control-input" />
                          <label class="custom-control-label" for="customCheck1">I agree with the Terms and
                            Conditions</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <!-- <div class="loader" v-if="loadingWizard"></div> -->
            </form-wizard>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
.loader,
.loader:after {
  border-radius: 100%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
